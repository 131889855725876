<template>
    <footer class="footer">
        <div class="container">
            <p>&copy; 2024 Dubai Exotics. All rights reserved.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'AppFooter',
}
</script>

<style scoped>
.footer {
    background-color: #1b1b1b;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}
</style>