import { createRouter, createWebHistory } from 'vue-router';
import AppHome from '../views/AppHome.vue';
import AppAbout from '../views/AppAbout.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: AppHome
    },
    {
        path: '/about',
        name: 'about',
        component: AppAbout
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;