<template>
  <div class="about">
    <section class="hero">
      <h1>About Luxury Supercar Rentals</h1>
      <p>Your gateway to the ultimate driving experience.</p>
    </section>

    <section class="mission">
      <h2>Our Mission</h2>
      <p>At Luxury Supercar Rentals, our mission is to provide an unparalleled driving experience, offering the world's
        most exclusive cars for enthusiasts and thrill-seekers alike. We are committed to delivering exceptional service
        and ensuring every moment behind the wheel is unforgettable.</p>
    </section>

    <section class="team">
      <h2>Meet Our Team</h2>
      <p>Our team consists of experienced professionals who are passionate about cars and dedicated to making your
        experience extraordinary.</p>
      <!-- Example team member -->
      <div class="team-member">
        <img src="@/assets/team1.png" alt="Team Member 1" />
        <h3>John Doe</h3>
        <p>Founder & CEO</p>
      </div>
      <!-- Add more team members as needed -->
    </section>

    <section class="contact">
      <h2>Contact Us</h2>
      <p>Have questions? Want to book your next supercar experience? Get in touch with us!</p>
      <button class="btn btn-primary">Contact Us</button>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AppAbout',
}
</script>

<style scoped>
.about {
  text-align: center;
  padding: 20px;
}

.hero {
  background-image: url('@/assets/about-hero-bg.png');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 100px 20px;
}

.hero h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.mission,
.team,
.contact {
  margin-top: 50px;
  padding: 20px;
}

.mission h2,
.team h2,
.contact h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.mission p,
.team p,
.contact p {
  font-size: 1.2em;
  line-height: 1.6;
}

.team-member {
  background-color: #fff;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.team-member img {
  max-width: 150px;
  height: auto;
  display: block;
  margin: 0 auto 15px;
  border-radius: 50%;
}

.team-member h3 {
  margin-bottom: 10px;
}

.btn-primary {
  background-color: #f4c542;
  color: #000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: 700;
}
</style>