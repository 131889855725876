<template>
    <header class="header">
        <div class="container">
            <!-- Logo -->
            <div class="logo">
                <img style="height: 50px;" src="@/assets/logo.png" alt="Luxury Supercar Rentals" />
            </div>

            <!-- Hamburger Menu Button (Visible on Mobile) -->
            <button class="hamburger" @click="toggleMenu">
                <i class="fas fa-bars"></i>
            </button>

            <!-- Navigation Links -->
            <nav class="nav" :class="{ 'nav-open': isMenuOpen }">
                <ul class="nav-list">
                    <li class="nav-item"><a href="#" class="nav-link"
                            @click.prevent="scrollToSection('services')">Services We Offer</a></li>
                    <li class="nav-item"><a href="#" class="nav-link" @click.prevent="scrollToSection('fleet')">Our
                            Fleet</a></li>
                    <li class="nav-item"><a href="#" class="nav-link"
                            @click.prevent="scrollToSection('why-choose-us')">Why Choose Us</a></li>
                    <li class="nav-item"><a href="#" class="nav-link" @click.prevent="scrollToSection('faq')">FAQ</a>
                    </li>
                    <li class="nav-item"><a href="#" class="nav-link"
                            @click.prevent="scrollToSection('contact')">Contact Us</a></li>
                </ul>
            </nav>


            <!-- Action Buttons -->
            <div class="action-buttons">
                <!-- <button class="btn btn-primary">Rent a Car</button> -->
                <div class="icons">
                    <!-- <i class="fas fa-envelope"></i> -->
                    <!-- <i class="fas fa-phone"></i> -->
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.min.css';
export default {
    name: 'AppHeader',
    data() {
        return {
            isMenuOpen: false
        };
    },
    methods: {
        toggleMenu() {
            if (this.isMenuOpen) {
                // Closing the menu
                const nav = this.$el.querySelector('.nav');
                nav.classList.add('nav-closing');
                setTimeout(() => {
                    this.isMenuOpen = false;
                    nav.classList.remove('nav-closing');
                }, 500); // Match the duration of bounceClose animation
            } else {
                // Opening the menu
                this.isMenuOpen = true;
            }
        },
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>

<style scoped>
@keyframes bounceOpen {
    0% {
        max-height: 0;
        transform: scaleY(0.95);
    }

    80% {
        max-height: 280px;
        transform: scaleY(1.05);
    }

    100% {
        max-height: 300px;
        transform: scaleY(1);
    }
}

@keyframes bounceClose {
    0% {
        max-height: 300px;
        transform: scaleY(1);
    }

    20% {
        max-height: 280px;
        transform: scaleY(1.05);
    }

    100% {
        max-height: 0;
        transform: scaleY(0.95);
    }
}


.header {
    background-color: #1b1b1b;
    padding: 10px 0;
    position: relative;
    z-index: 1000;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    /* padding: 0 20px; */
}

.logo {
    flex: 1;
}

.nav {
    flex: 2;
    display: flex;
    justify-content: center;
    transition: max-height 0.3s ease;
    z-index: 1000;
}

.nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    display: flex;
    gap: 20px;
}

.nav-item {}

.nav-link {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
}

.nav-link.active {
    color: #f4c542;
    /* Gold color */
}

.action-buttons {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}

.btn-primary {
    background-color: #f4c542;
    color: #000;
    border: none;
    padding: 10px 20px;
    font-weight: 700;
    cursor: pointer;
}

.icons {
    display: flex;
    gap: 10px;
}

.icons i {

    font-size: 20px;
    cursor: pointer;
}

/* Hamburger Menu Styles */
.hamburger {
    display: none;
}

.nav {
    transition: max-height 0.3s ease;
}

.nav-open {
    max-height: 350px;
}

@media (max-width: 768px) {
    .container {
        justify-content: center;
        position: relative;
        /* Add this to contain the hamburger's absolute positioning */
    }

    .hamburger {
        display: block;
        background: none;
        border: none;
        color: #fff;
        font-size: 30px;
        cursor: pointer;
        position: absolute;
        right: 20px;
        /* Position it to the right of the screen */
        top: 10px;
    }


    .nav {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #1b1b1b;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-height: 0;
        overflow: hidden;
        z-index: 1000;
    }

    .nav-list {
        flex-direction: column;
        gap: 10px;
    }

    .action-buttons {
        display: none;
        /* Hide action buttons on mobile */
    }

    .nav-open {
        max-height: 350px;
        animation: bounceOpen 0.5s ease forwards;
    }

    .nav {
        &.nav-closing {
            animation: bounceClose 0.5s ease forwards;
        }
    }
}
</style>